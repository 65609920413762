import styled from "styled-components";
const PageHeading = styled.h2`

    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    color: #898989;
    margin: 0px;
    line-height: 1em;
    margin-bottom: 40px;
    br{
        margin: 0px;
        padding: 0px;
        line-height: 0em;
    }
    span{
        margin: 0px;
        padding: 0px;
        line-height: 0em;
    }
    
    .heading-top{
        font-size: 1.5em;
        @media only screen 
        and (min-width: 1px) 
        and (max-width: 650px)  {
            font-size: 1em;
        } 
    }
    .heading-line{
        margin: 0px;
        padding: 0px;
        height: 3px;
        margin-right: 280px;
        margin-left: 280px;
        line-height: 0em;
        @media only screen 
        and (min-width: 1px) 
        and (max-width: 650px)  {
            margin-right: 80px;
            margin-left: 80px;
        } 
        @media only screen 
        and (min-width: 651px) 
        and (max-width: 900px)  {
            margin-right: 180px;
            margin-left: 180px;
        } 
    }
    .heading-main{
        margin: 0px;
        padding: 0px;
        font-size: 1.5em;
        line-height:1.2em;
        margin-top: 0px;
        @media only screen 
        and (min-width: 1px) 
        and (max-width: 650px)  {
            font-size: 1em;
        } 
    }
    
`

export default PageHeading;