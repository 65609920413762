import { React, styled } from "../shipityo/index";
import Layout from "../layouts/index";

import PageHeading from "../helpers/page-heading";
import Img from "gatsby-image";


const Container = styled.div`
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 80px;
    padding-right: 80px;
    text-align: center;
    color: #6a6a6a;
    @media only screen 
    and (max-width: 650px)  {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
    } 
    
`;

const AboutInner = styled.div`

    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: auto;
    div {
        max-width: 500px;
        padding: 20px;
    }
    @media only screen 
    and (max-width: 650px)  {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
    } 
`;

class Component extends React.Component {

    constructor({data}) {
        let  pageImages =[];
        super();
        this.state = {
        };
        this.render = this.render.bind(this);
        if(data){
            if(data.allFile){
                if(data.allFile.edges){
                    pageImages = data.allFile.edges
                    this.state = {pageImages: pageImages};
                } 
            } 
        }
    }
  
    render() {
        return (
        <Layout>
        <div>
            <div id="home-images">
                    {this.state.pageImages.map((image, index) => (
                        <div>
                            <Img key={index} sizes={image.node.childImageSharp.sizes} />
                        </div>
                    ))}
            </div>
            <Container>
                <PageHeading>
                    <span className="heading-top">CULTURE</span><br/>
                    <br/>
                    <hr className="heading-line"/><br/>
                    <span className="heading-main">ABOUT LOGGENBERG LITIGATION ATTORNEYS</span>
                </PageHeading>
                <AboutInner>
                    <div>
                        <h3>WHY HIRE LOGGENBERG ATTORNEYS?</h3>
                        <p>
                            Loggenberg Attorneys Incorporated is a boutique law firm located in Retreat, Cape Town, Western Cape. The firm is a civil litigation law practice providing service in most areas including labour law, family law, personal injury, contract law and human rights law.
                        </p>
                    </div>
                    <div>
                        <h3>RESULTS</h3>
                        <p>
                            We offer a contingency legal service meaning we are able to represent individuals on cases with good prospects of success where fees are payable only if there is a favourable result. 
                        </p>
                    </div>
                    <div>
                        <h3>EXPERIENCE</h3>
                        <p>
                            Loggenberg Attorneys Inc. was established in 2015 by its sole director, Motshidisi Loggenberg, who has vast experience in the public sector and in health services. The firm is committed to ensuring that the rights of its clients protected and only the best interest of the clients are served. 
                        </p>
                    </div>
                    <div>
                        <h3>ETHICS</h3>
                        <p>
                            Our ethical business model is based on understanding that listening attentively to the legal needs of a client and providing practical client tailored solutions improves the quality of representation and client satisfaction. At the same time, the attorneys are sensitized to provide the best legal services at the most affordable cost.
                        </p>
                    </div>
                    <div>
                        <h3>PROFESSIONAL & PERSONAL</h3>
                        <p>
                            Our mission is to strive to be the leading civil litigation law firm through our commitment to rendering a high quality, professional legal service tailored to the client’s needs.
                        </p>
                    </div>
                </AboutInner>
            </Container>
        </div>
        </Layout>
        ) 
    }
  }


  export default Component;
  
  export const query = graphql`
    query AboutQuery {
      site {
        siteMetadata {
          title
        }
      }
      allFile(filter: {relativeDirectory: {regex: "/img/pagebanners/about/"}}) {
        edges {
          node {
            name
            relativePath
            prettySize
            extension
            birthTime
            childImageSharp {
              sizes(maxWidth: 1900) {
                ...GatsbyImageSharpSizes_tracedSVG
              }
            }
          }
        }
      }
    }
  `